import { Suspense, lazy, ElementType } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import AuthGuard from '../components/authentication/AuthGuard';
import GuestGuard from '../components/authentication/GuestGuard';
import RequireAuth from '../components/authentication/RequireAuth';
import LoadingScreen from '../components/shared/loaders/LoadingScreen';
import DocsDemo from '../docs/DocsDemo';
import DocsLayout from '../docs/DocsLayout';
import MainContainer from '../layouts/MainContainer';
import WeeklyAttendanceContainer from '../layouts/WeeklyAttendanceContainer';
import { MinimumPermission } from '../types/claims/profilePermission';
import Login from '../views/Login';

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loadable(lazy(() => import('../views/Home')));
const WeekView = Loadable(
  lazy(() => import('../views/roster/WeeklyAttendanceView'))
);
const MonthView = Loadable(
  lazy(() => import('../views/roster/MonthlyAttendanceView'))
);
const ScheduleLoad = Loadable(
  lazy(() => import('../views/roster/ScheduleLoadView'))
);
const WorkedSummary = Loadable(
  lazy(() => import('../views/roster/WorkedSummaryView'))
);
const ApplicationsViews = Loadable(
  lazy(() => import('../views/metbus-apps/MetbusAppsView'))
);
const ExchangesView = Loadable(
  lazy(() => import('../views/exchanges/ExchangesView'))
);
const HolidaysMaintainerView = Loadable(
  lazy(() => import('../views/maintainers/HolidaysMaintainerView'))
);
const TaskHistoryView = Loadable(
  lazy(() => import('../views/controlPanel/TaskHistoryView'))
);
const UpdateDBView = Loadable(
  lazy(() => import('../views/controlPanel/UpdateDBView'))
);
const UserListView = Loadable(
  lazy(() => import('../views/userManager/UserListView'))
);
const UserCreateView = Loadable(
  lazy(() => import('../views/userManager/UserCreateView'))
);
const UserEditView = Loadable(
  lazy(() => import('../views/userManager/UserEditView'))
);
const SystemListView = Loadable(
  lazy(() => import('../views/userManager/SystemListView'))
);
const SystemCreateView = Loadable(
  lazy(() => import('../views/userManager/SystemCreateView'))
);
const ProfileListView = Loadable(
  lazy(() => import('../views/userManager/ProfileListView'))
);
const ProfileCreateView = Loadable(
  lazy(() => import('../views/userManager/ProfileCreateView'))
);
const ActionListView = Loadable(
  lazy(() => import('../views/mailing/ActionListView'))
);
const ActionCreateView = Loadable(
  lazy(() => import('../views/mailing/ActionCreateView'))
);
const RecipientListView = Loadable(
  lazy(() => import('../views/mailing/RecipientsListView'))
);
const RecipientCreateView = Loadable(
  lazy(() => import('../views/mailing/RecipientsCreateView'))
);
const GroupListView = Loadable(
  lazy(() => import('../views/mailing/GroupListView'))
);
const GroupCreateView = Loadable(
  lazy(() => import('../views/mailing/GroupCreateView'))
);
const GroupEditView = Loadable(
  lazy(() => import('../views/mailing/GroupEditView'))
);
const ConfigurationListView = Loadable(
  lazy(() => import('../views/mailing/ConfigurationListView'))
);
const ConfigurationCreateView = Loadable(
  lazy(() => import('../views/mailing/ConfigurationCreateView'))
);
const NotFound = Loadable(lazy(() => import('../views/NotFound')));

const routes: RouteObject[] = [
  {
    element: <GuestGuard />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainContainer />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'aplicaciones-metbus',
        element: <ApplicationsViews />,
      },
      {
        path: 'docs',
        element: <DocsLayout />,
        children: [
          {
            index: true,
            element: (
              <Navigate
                to="/docs/asistencia/iniciodocumentacion"
                replace
              />
            ),
          },
          {
            path: '*',
            element: <DocsDemo />,
          },
        ],
      },
      {
        path: 'roster',
        children: [
          {
            path: 'vista-mensual',
            element: (
              <RequireAuth
                moduleClaim="RosterVistaMensual"
                minimumPermission={MinimumPermission.ReadOnly}
              />
            ),
            children: [
              {
                index: true,
                element: <MonthView />,
              },
            ],
          },
          {
            path: 'carga-de-programacion',
            element: (
              <RequireAuth
                moduleClaim="RosterCargaDeProgramacion"
                minimumPermission={MinimumPermission.ReadAndWrite}
              />
            ),
            children: [
              {
                index: true,
                element: <ScheduleLoad />,
              },
            ],
          },
          {
            path: 'resumen-trabajado',
            element: (
              <RequireAuth
                moduleClaim="RosterResumenTrabajado"
                minimumPermission={MinimumPermission.ReadOnly}
              />
            ),
            children: [
              {
                index: true,
                element: <WorkedSummary />,
              },
            ],
          },
        ],
      },
      {
        path: 'intercambios',
        element: (
          <RequireAuth
            moduleClaim="Exchanges"
            minimumPermission={MinimumPermission.ReadOnly}
          />
        ),
        children: [
          {
            index: true,
            element: <ExchangesView />,
          },
        ],
      },
      {
        path: 'mantenedores',
        children: [
          {
            path: 'feriados',
            element: (
              <RequireAuth
                moduleClaim="HolidayMaintainer"
                minimumPermission={MinimumPermission.ReadOnly}
              />
            ),
            children: [
              {
                index: true,
                element: <HolidaysMaintainerView />,
              },
            ],
          },
        ],
      },
      {
        path: 'control',
        children: [
          {
            path: 'actualizaciones',
            element: (
              <RequireAuth
                moduleClaim="ControlUpdater"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <UpdateDBView />,
              },
            ],
          },
          {
            path: 'historial-procesos',
            element: (
              <RequireAuth
                moduleClaim="ControlTaskHistory"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <TaskHistoryView />,
              },
            ],
          },
        ],
      },
      {
        path: 'user-manager',
        children: [
          {
            path: 'usuarios',
            element: (
              <RequireAuth
                moduleClaim="UserMaintainer"
                minimumPermission={MinimumPermission.ReadOnly}
              />
            ),
            children: [
              {
                index: true,
                element: <UserListView />,
              },
              {
                path: 'crear',
                element: <UserCreateView />,
              },
              {
                path: 'editar',
                element: <UserEditView />,
              },
            ],
          },
          {
            path: 'sistemas',
            element: (
              <RequireAuth
                moduleClaim="SystemMaintainer"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <SystemListView />,
              },
              {
                path: 'crear',
                element: <SystemCreateView />,
              },
              {
                path: 'editar',
                element: <SystemCreateView />,
              },
            ],
          },
          {
            path: 'perfiles',
            element: (
              <RequireAuth
                moduleClaim="ProfileMaintainer"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <ProfileListView />,
              },
              {
                path: 'crear',
                element: <ProfileCreateView />,
              },
              {
                path: 'editar',
                element: <ProfileCreateView />,
              },
            ],
          },
        ],
      },
      {
        path: 'mailing',
        children: [
          {
            path: 'acciones',
            element: (
              <RequireAuth
                moduleClaim="ActionMaintainer"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <ActionListView />,
              },
              {
                path: 'crear',
                element: <ActionCreateView />,
              },
            ],
          },
          {
            path: 'destinatarios',
            element: (
              <RequireAuth
                moduleClaim="RecipientMaintainer"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <RecipientListView />,
              },
              {
                path: 'crear',
                element: <RecipientCreateView />,
              },
              {
                path: 'editar',
                element: <RecipientCreateView />,
              },
            ],
          },
          {
            path: 'grupos',
            element: (
              <RequireAuth
                moduleClaim="GroupMaintainer"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <GroupListView />,
              },
              {
                path: 'crear',
                element: <GroupCreateView />,
              },
              {
                path: 'editar',
                element: <GroupEditView />,
              },
            ],
          },
          {
            path: 'configuraciones',
            element: (
              <RequireAuth
                moduleClaim="ConfigurationMaintainer"
                minimumPermission={MinimumPermission.SuperUser}
              />
            ),
            children: [
              {
                index: true,
                element: <ConfigurationListView />,
              },
              {
                path: 'crear',
                element: <ConfigurationCreateView />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <WeeklyAttendanceContainer />
      </AuthGuard>
    ),
    children: [
      {
        path: '/roster/vista-semanal',
        element: (
          <RequireAuth
            moduleClaim="RosterVistaSemanal"
            minimumPermission={MinimumPermission.ReadOnly}
          />
        ),
        children: [
          {
            index: true,
            element: <WeekView />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
